import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventCalendar from '../components/EventCalendar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import EventData from '../content/events.json';
import EventCard, { formatEventHours} from '../components/EventCard';
import { generateEventPageUrl } from '../utils';
import { navigate } from "gatsby"

//TODO make sure you get curremt year. 
const events = EventData.events.map((event) => {
    return {...event, 
        start: new Date(`2022-${event.start.month}-${event.start.day}T${event.start.hours}:${event.start.minutes}`),
        end: new Date(`2022-${event.end.month}-${event.end.day}T${event.end.hours}:${event.end.minutes}`),
        allday: false,
        resource: false}
  })

  const structuredData = `
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": "1",
        "item": {
          "@type": "Course",
          "name": "High School Clinics",
          "description": "All Skills Clinics for Girls Ages 14 to 18",
          "provider": {
            "@type": "Organization",
            "name": "Blue Crabs Volleyball",
            "sameAs" : ["https://www.facebook.com/bluecrabsbvb",
            "https://twitter.com/bluecrabsbvb",
            "https://www.instagram.com/bluecrabsbvb/"]
          }
        },
    ]
  }`

export default function Clinics(props){

    const [selectedEvent, selectEvent] = React.useState(props.pageContext.eventData ? props.pageContext.eventData : null);
    const data = props.data;  
    return (
        <Layout>
        <SEO title={selectedEvent ?  selectedEvent.title : 'Clinics'} 
          image={selectedEvent ? data[selectedEvent.imageKey].childImageSharp.resize : null}
          description="play with collegiate athletes, compete, raise your game!"
          structuredData={selectedEvent ? selectedEvent.structuredData : structuredData}/>
        <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
            <Grid item style={{padding: '2rem'}} xs={12}>
                <Typography variant="h3" style={{color: '#243E83'}}>Clinics For All Players</Typography>
                <Typography variant="h4"><i>Improve your skills, get reps, and have fun playing volleyball!</i></Typography>
            </Grid>
            <Grid item style={{padding: '1rem', width: '90%'}} xs={12}>
                <EventCalendar events={events} height={495}
                    onSelectEvent={(eventObj, e) => { navigate('/clinics/'+generateEventPageUrl(eventObj))}}/>
            </Grid>
            <Grid item xs={12} container direction="column" spacing={2} sm={8} style={{padding: '2rem'}}>
                <Typography variant="h4" style={{color: '#243E83', marginBottom: '10px'}}>The Dive-In Approach</Typography>
                <Typography variant="paragraph" style={{marginBottom: '10px'}}>
                  We believe one of the best ways to learn and improve is to simply be exposed to higher level play and as such all our clinics put an emphasis
                  on gameplay drills and active coach participation. Our coaches are great at bringing out an athlete's best play!<br></br>
                  <br></br>Below are some additional details of what you can expect at some of our more frequently occurring clinic types.                
                </Typography>
                <Grid item>
                    <Typography variant="paragraph" style={{marginBottom: '10px'}}>
                    <b style={{borderBottom: '1px solid #F78D1F', verticalAlign: 'super'}}>All Skills Clinic</b><br></br>
                    These clinics are designed for girls entering 9th, 10th, 11th, and 12th grade. 
                    A consistent level of volleyball experience is recommended for these sessions. These clinics will have a conditioning component 
                    in addition to skill development (serving, passing, setting, hitting) and more advanced game play techniques and drills. 
                    This type of clinic is perfect for players who are interested in sharpening their skills, learning more about beach volleyball, 
                    and staying in shape in preparation for their high school seasons.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="paragraph" style={{marginBottom: '10px'}}>
                    <b style={{borderBottom: '1px solid #F78D1F', verticalAlign: 'super'}}>Middle School Clinic</b><br></br>
                    These clinics are designed for girls in the U12 and U14 age groups. We offer beginner and intermediate clinics 
                    for middle schoolers. New players are encouraged to attend beginner sessions while players with competitive volleyball 
                    experience are encouraged to sign up for intermediate levels. The sessions will cover basic skills like serving, 
                    passing, setting, and hitting and some game play techniques and drills. These clinics are perfect for young players who are 
                    interested in learning more about volleyball and sharpening their skills</Typography>
                    <br></br>
                    Lastly we do also offer clinics and lessons for boys of all ages.
                </Grid>
            </Grid>
            <Modal open={selectedEvent!=null} onClose={() => navigate('/clinics/')} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {selectedEvent !=null && <EventCard title={selectedEvent.title} variant="popover" closePopover={() => navigate('/clinics/')}
                    // dimensions={{height: '80vh', width: `100vh`}}
                    time={{day: selectedEvent.date, hours: formatEventHours(
                        typeof selectedEvent.start.getMonth == 'function' ? {hours: selectedEvent.start.getHours(), minutes: selectedEvent.start.getMinutes()} :
                            {hours: selectedEvent.start.hours, minutes: selectedEvent.start.minutes},
                        typeof selectedEvent.end.getMonth == 'function' ? {hours: selectedEvent.end.getHours(), minutes: selectedEvent.end.getMinutes()} :
                        {hours: selectedEvent.end.hours, minutes: selectedEvent.end.minutes}
                    )}}
                    formId={selectedEvent.formId}
                    shareUrl={props.location.href}
                    location={selectedEvent.location}
                    ageGroups={selectedEvent.ageGroups}
                    image={data[selectedEvent.imageKey].childImageSharp.gatsbyImageData} 
                    coaches={selectedEvent.coaches.map((coach) => {
                        return {image: data[coach.id].childImageSharp.gatsbyImageData, label: coach.name, id: coach.id}
                    })}/>}
            </Modal>
        </Grid>
        </Layout>
    )
}

export const query = graphql`
query {
  passing: file(relativePath: {eq: "events/passing.jpg"}) {
      ...cardImage
  }
  digging: file(relativePath: {eq: "events/digging.jpg"}) {
      ...cardImage
  }
  serving: file(relativePath: {eq: "events/serving.jpg"}) {
    ...cardImage
  }
  hitting: file(relativePath: {eq: "events/hitting.jpg"}) {
      ...cardImage
  }
  blocker: file(relativePath: {eq: "events/blocker.jpg"}) {
      ...cardImage
  }
  jump_serve: file(relativePath : {eq: "events/jump_serve.jpg"}){
    ...cardImage
  }
  spike: file(relativePath : {eq: "events/spike.jpg"}){
    ...cardImage
  }
  tournament: file(relativePath: {eq: "events/tournament.jpg"}){
    ...cardImage
  }
  kristen_falcinelli: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
    ...cardImage
  }
  kelly_hughes: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
    ...cardImage
  }
  enzo_lulushi: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
    ...cardImage
  }
  edina_blazevic: file(relativePath: {eq: "edina_blazevic_profile.jpg"}) {
    ...cardImage
  }
  jordan_mckinney: file(relativePath: {eq: "jordan_mckinney_profile.jpg"}) {
    ...cardImage
  }
}
`